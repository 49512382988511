.nft_list_item {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  .nft_list_item_com {
    .item_bg {
      position: relative;
      overflow: hidden;
      &:hover {
        .item_logo {
          transform: scale(1.1);
        }
      }
      .item_logo {
        display: block;
        width: 100%;
        height: auto;
        border-radius: 10px 10px 0 0;
        margin: 0;
        padding: 0;
        transition: all 0.3s ease-in-out;
      }
      .nft_list_item_moda {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        position: absolute;
        top: 0;
        left: 100%;
        background: linear-gradient(
          32deg,
          rgba(74, 121, 249, 0.9) 0%,
          rgba(10, 155, 227, 0.9) 56.00000000000001%,
          rgba(179, 119, 248, 0.9) 99%
        );
        transition: all 0.3s;
        padding: 10px;
        border-radius: 10px 10px 0 0;
        .desc_title {
          text-align: center;
          font-size: 20px;
          font-weight: 700;
        }
        .desc_info {
          margin-top: 20px;
          .desc_text {
            font-size: 16px;
          }
        }
        .desc_con {
          margin-top: 20px;
          .desc_con_tit {
            font-size: 16px;
          }
          .desc_con_text {
            line-height: 16px;
            font-size: 12px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      &:hover {
        .nft_list_item_moda {
          left: 0;
        }
      }
    }

    .item_con {
      width: 100%;
      background: #151a22;
      border-radius: 0 0 10px 10px;
      padding: 10px 11px;
      .item_sign {
        font-size: 14px;
        color: #fff;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .item_name {
        font-size: 12px;
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .item_name_pc {
        display: block;
      }
      .item_name_mobile {
        display: none;
      }
      .line {
        color: #fff;
        font-size: 14px;
        margin: 0;
        margin-top: 5px;
        &::after,
        &:before {
          border-color: #3f444b;
        }
      }
      .line_nop {
        border-bottom: 1px solid #3f444b;
        margin-top: 10px;
      }
      .item_func {
        display: flex;
        align-items: center;
        margin-top: 15px;
        .item_curr_con {
          display: flex;
          align-items: center;
        }
        .item_curr {
          img {
            width: 24px;
            height: 24px;
          }
        }
        .item_price {
          font-size: 14px;
          font-weight: bold;
          margin-left: 5px;
        }
        .buy_btn {
          width: 100px;
          height: 30px;
          text-align: center;
          line-height: 30px;
          font-size: 12 px;
          font-weight: bold;
          background: #023a59;
          margin-left: auto;
          border-radius: 5px;
          cursor: pointer;
          &:active {
            border-radius: 5px;
            background: #021a27;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .nft_list_item {
    .nft_list_item_com {
      .item_bg {
        .nft_list_item_moda {
          .desc_title {
            font-size: 14px;
          }
          .desc_info {
            margin-top: 5px;
            .desc_text {
              font-size: 12px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          .desc_con {
            margin-top: 5px;
            .desc_con_tit {
              font-size: 12px;
            }
            .desc_con_text {
              font-size: 12px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
        &:active {
          .nft_list_item_moda {
            left: 0;
          }
        }
      }
      .item_con {
        height: auto;
        .item_sign {
          font-size: 12px;
        }
        .item_name {
          font-size: 14px;
          .item_name_text {
            display: none;
          }
        }
        .item_name_pc {
          display: none;
        }
        .item_name_mobile {
          display: block;
        }
        .item_func {
          flex-direction: column;
          align-items: flex-start;
          .item_curr_con {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
          }
          .item_curr {
            img {
              width: 24px;
              height: 24px;
            }
          }
          .item_price {
            font-size: 14px;
          }
          .buy_btn {
            width: 100%;
            height: 40px;
            line-height: 40px;
            font-size: 14px;
            transform: translateY(0px);
            transition: all 0.3s;
          }
          .show_buy_btn {
            transform: translateY(0px);
          }
        }
      }
    }
  }
}
