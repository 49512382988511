

.g_footer {
  width: 100%;
  height: 3.125rem;
  margin-top: 3.125rem;
  display: flex;
  align-items: center;
  .g_footer_link {
    width: 68.75rem;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    .link {
      margin-right: 1.5rem;

      > img {
        height: 1rem;
      }
    }
    .link_b {
      display: none;
    }
    .medium,
    .nh {
      > img {
        height: 0.875rem;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .g_footer {
    height: 100%;
    padding: 0 0.625rem;
    margin-top: 0;
    .g_footer_link {
      height: 3.125rem;
      width: 100%;
      display: flex;
      justify-content: center;
      .link {
        > img {
          height: 1rem;
        }
      }
      .link_h {
        display: none;
      }
      .link_b {
        display: block;
      }
      .medium,
      .nh {
        > img {
          height: 0.875rem;
        }
      }
    }
  }
}
