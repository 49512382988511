// home.less

.homenft-bg{
  background: linear-gradient(0deg, rgba(236, 255, 240, 1) 0%, rgba(255, 255, 255, 1) 100%);
}

.homenft-wrapper {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.homenft-gradient-bg {
  position: absolute;
  top: 155px;  // 20px padding + 15px offset
  left: 85px; // 20px padding + 15px offset
  right: 15px;
  bottom: 15px;
  background: linear-gradient(270deg, rgba(200, 234, 48, 1) 0%, rgba(0, 149, 36, 1) 100%);
  border-radius: 20px;
  z-index: 1;
}

.homenft-container {
  padding: 20px;
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

.homenft-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px;
}

.homenft-title {
  color: rgba(0, 0, 0, 1);
  font-family: Arial;
  font-weight: 700;
  font-size: 36px;

}

.homenft-learn-more {
  color: rgba(51, 51, 51, 1);
  margin-bottom: 15px;
  font-family: Arial;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  text-decoration-line: underline;
  &:hover {
    text-decoration: underline;
  }
}

.homenft-content {
  position: relative;
  border-radius: 20px;
  border: 3px solid rgba(0, 0, 0, 1);
  box-sizing: border-box;
  background: rgba(255, 255, 255, 1);
  overflow: hidden;
  z-index: 2;
}

// 添加渐变背景边框
.homenft-content::before {
  content: '';
  position: absolute;
  top: 15px;
  left: 15px;
  width: 100%;
  height: 100%;
  border-radius: 18px;
  z-index: -1;
}

.homenft-rankings-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
}

// 添加中间分隔线
.homenft-rankings-container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  width: 1px;
}

.homenft-rankings-column {
  display: flex;
  flex-direction: column;
}

.homenft-table-header {
  display: grid;
  grid-template-columns: 30px 40px auto 100px 100px;
  gap: 10px;
  padding: 15px 20px;
  border-bottom: 1px solid #eee;
}

.homenft-header-ranking,
.homenft-header-floor,
.homenft-header-highest {
  color: rgba(0, 0, 0, 1);
  font-family: Arial;
  font-weight: 400;
  font-size: 14px;
}

.homenft-ranking-item {
  display: grid;
  grid-template-columns: 30px 40px auto 100px 100px;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  &:hover {
    background: #f9f9f9;
  }
}

.homenft-rank {
  color: #666;
  font-size: 14px;
}

.homenft-nft-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.homenft-nft-name {
  font-size: 14px;
  font-weight: 500;
}

.homenft-floor-price {
  color: rgba(0, 149, 36, 1);
  font-family: Arial;
  font-weight: 700;
  font-size: 16px;
}

.homenft-highest-price {
  color: rgba(229, 28, 28, 1);
  font-family: Arial;
  font-weight: 700;
  font-size: 16px;
}