.profile {
  padding-top: 61px;
  color: #fff;
  position: relative;
  background: #000;
  min-height: calc(100vh - 181px);
  .home_bg {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    .home_top_bg {
      width: 100%;
      display: none;
    }
  }

  .home_content {
    position: relative;
    z-index: 10;
    .home_logo {
      width: 100%;
      height: 100%;
      margin-top: 84px;
      display: flex;
      justify-content: center;
      > img {
        width: 340px;
      }
    }
    .adopts {
      width: 150px;
      height: 24px;
      border: 1px solid #eee;
      border-radius: 5px;
      text-align: center;
      margin: 15px auto;
    }
    .home_jinren {
      width: 100%;
      display: flex;
      justify-content: center;
      > img {
        width: 214px;
        height: 392px;
        margin-top: 26px;
      }
    }
    .mart_content {
      display: flex;
      flex-direction: column;
      align-items: center;
      .mart_logo {
        width: 90px;
        height: 88px;
        margin-top: 57px;
      }
      .mart_title {
        width: 117px;
        height: 23px;
        margin-top: 10px;
      }
      p {
        font-size: 15px;
        text-align: center;
        padding: 0 30px;
        margin-top: 10px;
      }
    }
  }
  .home_con {
    position: relative;
    .home_con_bg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      background: #000;

      .home_content {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 126px;
        left: 0;
      }
    }
    .color_bar {
      width: 100%;
      height: 5px;
    }
    .home_con_content {
      position: relative;
      z-index: 10;
      padding-top: 10px;
      .home_con_content_main {
        padding: 0 11px;
        .adopt_box {
          position: relative;
          .adopt_box_con {
            width: 100%;
            padding: 50px 11px 20px;
            background: url("../../assets/img/home/adopt_box.png");
            background-size: 100% 100%;

            .adopt_title {
              display: flex;
              justify-content: space-between;
              > img {
                width: 117px;
                height: 23px;
              }
              .adopt_title_btn {
                width: 71px;
                height: 26px;
                text-align: center;
                line-height: 26px;
                font-size: 13px;
                background: url("../../assets/img/home/btn_border.png");
                background-size: 100% 100%;
              }
            }
            .adopt_desc {
              display: flex;
              justify-content: space-between;
              gap: 10px;
              margin-top: 30px;
              .adopt_desc_left {
                flex: 1;
              }
              .adopt_desc_right {
                flex: 1;
              }
              .adopt_desc_box {
                width: 100%;
                background: url("../../assets/img/home/adopt_desc_bg.png");
                background-size: 100% 100%;
                padding: 20px 10px;
                font-size: 12px;
                > h3 {
                  font-size: 13px;
                  margin-bottom: 10px;
                  font-weight: bold;
                }
                &:last-child {
                  margin-top: 10px;
                }
              }
            }
          }
        }
        .adopt_list {
          width: 100%;
          margin-top: 20px;
          border: 1px solid #004473;
          border-radius: 10px;
          padding: 11px 11px 0;
          .adopt_list_title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .adopt_list_title_left {
              display: flex;
              align-items: center;
              .adopt_list_title_logo {
                width: 39px;
                height: 39px;
              }
              .adopt_list_title_font {
                width: 117px;
                height: 23px;
                margin-left: 10px;
              }
            }
            .adopt_list_title_right {
              width: 71px;
              height: 26px;
              text-align: center;
              line-height: 26px;
              font-size: 13px;
              background: url("../../assets/img/home/btn_border.png");
              background-size: 100% 100%;
            }
          }
          .adopt_list_con {
            margin-top: 10px;
            .adopt_list_item {
              width: 100%;
              padding: 20px 0;
              display: flex;
              border-bottom: 1px solid #1c1829;
              &:last-child {
                border-bottom: none;
              }
              .adopt_list_item_logo {
                width: 46px;
                height: 48px;
                border-radius: 5px;
              }
              .adopt_list_item_con {
                flex: 1;
                height: 48px;
                margin-left: 10px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                font-size: 13px;
                font-weight: bold;

                .curr_info {
                  display: flex;
                  justify-content: space-between;
                  .curr_info_left {
                    display: flex;
                    align-items: center;
                    .curr_box {
                      width: 18px;
                      height: 18px;
                      background-image: url("../../assets/img/home/curr_bg.png");
                      background-size: 100% 100%;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      .curr_logo {
                        width: 12px;
                        height: 12px;
                      }
                    }
                    .curr_price {
                      padding: 0 3px;
                    }
                  }
                  .curr_info_right {
                    font-size: 17px;
                  }
                  .curr_info_right_up {
                    color: #00a57b;
                  }
                  .curr_info_right_down {
                    color: red;
                  }
                }
              }
            }
          }
        }

        .nft_logo {
          margin: 50px 0;
          display: flex;
          align-items: center;
          justify-content: center;
          .nft_logo_icon {
            width: 40px;
            height: 40px;
            margin-right: 5px;
          }
          .nft_logo_title {
            height: 23px;
          }
        }
        .mart_article {
          width: 100%;
          .mart_article_title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .mart_article_title_font {
              width: 117px;
              height: 23px;
            }
            .nft_title_btn {
              width: 71px;
              height: 26px;
              text-align: center;
              line-height: 26px;
              font-size: 13px;
              background: url("../../assets/img/home/btn_border.png");
              background-size: 100% 100%;
            }
          }
          .mart_article_box {
            margin-top: 20px;
            padding: 20px 11px;
            background: #0a0a0a;
            border-radius: 5px;
            &:last-child {
              margin-top: 10px;
            }
            h3 {
              font-size: 16px;
              font-weight: bold;
            }
            p {
              margin-top: 10px;
              font-size: 12px;
            }
          }
        }
      }

      .slogan_logo {
        width: 100%;
        background: url("../../assets/img/home/slogan_logo.png") no-repeat
          center center;
        background-size: cover; /* 这将保持图片的宽高比 */
        position: relative;
        .slogan_logo_btn {
          width: 121px;
          height: 24px;
          border: 1px solid #fff;
          border-radius: 12px;
          position: absolute;
          top: 120px;
          left: 16%;
        }
      }

      @media (max-width: 768px) {
        /* 对于小屏幕设备 */
        .slogan_logo {
          background-size: 100% 100%; /* 在小屏幕上可能需要全覆盖 */
        }
      }
    }
  }
}
@media screen and (min-width: 768px) {
  .profile {
    min-height: calc(100vh - 180px);
    .home_con .home_con_bg {
      height: 100%;
      .home_content {
        padding-bottom: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  .profile .home_bg .home_top_bg {
    display: none;
  }
}
