.homeintro-wrapper{
  // 方式一：使用 background-image
  background-image: url('../../../../assets/img/h/home_bg.png');  // 请根据实际图片路径修改
  background-size: cover;

  width: 100%;
  padding: 20px 0;
}

.homeintro {
  &-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    padding-bottom: 60px;
  }

  &-title {
    color: rgba(0, 0, 0, 1);
    font-family: Arial;
    font-weight: 700;
    font-size: 36px;
    text-align: center;
    margin-bottom: 40px;
  }

  &-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

  &-card {
    display: flex;
    align-items: center;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 16px;
    text-decoration: none;
    color: inherit;
    transition: transform 0.2s;

    &:hover {
      transform: translateY(-2px);
    }

    &-highlight {
      background: linear-gradient(#0db836cc, #0db836cc);
      color: white;

      .homeintro-description {
        color: rgba(255, 255, 255, 0.8);
      }
    }
  }

  &-logo {
    width: 60px;
    height: 60px;
    margin-right: 20px;
    object-fit: contain;

    @media (max-width: 480px) {
      width: 40px;
      height: 40px;
    }
  }

  &-content {
    flex: 1;
  }

  &-name {
    font-size: 20px;
    margin: 0 0 8px;

    @media (max-width: 480px) {
      font-size: 18px;
    }
  }

  &-description {
    font-size: 14px;
    color: #666;
    margin: 0 0 12px;
    line-height: 1.4;
  }

  &-check {
    font-size: 14px;
    font-weight: 500;
  }
}