.root{
  padding-top: 120px;
  min-height: calc(100vh - 190px);
  width: 70%;
  margin-left: 15%;
}

.container-ido{
  width: 100%;
  padding: 20px;
}

.top-card {
  width: 100%;
  background-color: #fff;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.top-card-container {
  flex: 1;
  padding: 10px;
  display: flex;
  align-items: center;
}

.top-card-container-text {
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.top-card-title{
  font-size: 24px;
  font-weight: bold;
  color: #000;
}

.top-card-desc{
  font-size: 18px;
  color: #5B5B5B;
}

.top-card-step-image{
  width: 50px;
  height: auto;
}

.top-card-image {
  width: 60px;
  height: auto;
  margin: 0 20px;
}

.flex-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.card {
  width: calc(50% - 10px); /* 容器宽度的一半减去间距的一半 */
  height: 600px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  border-radius: 16px;
}

.ido-pic{
  width: 100%;
  height: 450px;
}


.countdown-container {
  width: 100%;
  height: 200px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.countdown-img {
  width: auto;
  height: 42px;
  object-fit: cover;
  margin-left: 12px;
  margin-right: 12px;
}

.countdown {
  flex: 1;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.text-container {
  text-align: center;
}

.title_countdown {
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 5px;
}

.desc_countdown {
  font-size: 16px;
}

.gradient-text {
  background: linear-gradient(0deg, rgba(224,240,38,0.96) 0%, rgba(13,184,54,0.96) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.progress-bar-container {
  width: calc(100% - 40px); /* 固定宽度 */
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 10px;
  height: 20px; /* 固定高度 */
  background-color: #e0e0e0; /* 背景颜色 */
  border-radius: 10px; /* 圆角 */
  overflow: hidden; /* 隐藏溢出 */
}

.progress-bar {
  height: 100%;
  border-radius: 10px 0 0 10px; /* 只在左侧添加圆角 */
  background:linear-gradient(to right, rgba(224,240,38,0.96) 0%, rgba(13,184,54,0.96) 100%); /* 渐变颜色 */
  transition: width 0.3s ease; /* 动画效果 */
}

.progress-lable{
  width: calc(100% - 40px); /* 固定宽度 */
  margin-left: 20px;
  margin-right: 20px;
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
}

.ido-gobal-lable{
  width: calc(100% - 40px); /* 固定宽度 */
  margin-left: 20px;
  margin-right: 20px;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  font-size: 16px;
}

/* 为移动端添加媒体查询 */
@media (max-width: 768px) {
  .root {
    width: 100%;
    margin-left: 0; /* 确保不再有左侧的空隙 */
  }

  .flex-container {
    flex-direction: column;
  }

  .card {
    width: 100%;
    margin-bottom: 20px;
  }

  .top-card-title{
    font-size: 18px;
    font-weight: bold;
    color: #000;
  }

  .top-card-desc{
    font-size: 14px;
    color: #5B5B5B;
  }

  .top-card-step-image{
    width: 40px;
    height: auto;
  }

  .top-card-image {
    width: 40px;
    height: auto;
    margin: 0 5px;
  }
}