.title{
  font-size: 24px;
  font-weight: bold;
  color: #000;
  text-transform: uppercase;
}

.special-container{
  display: flex;
  flex-direction: column;
  margin: 10px;
  margin-right: 30px;
}

.special-container a {
  color: #222222; /* 链接颜色 */
  font-size: 14px; /* 链接字体大小 */
  text-decoration: none; /* 移除下划线 */
  margin-top: 3px;
}

.footer{
  height: 200px;
  background: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 15%;
  padding-right: 15%;
}

@media (max-width:767px)
{

  .footer{
    height: auto;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 50px;
    padding-bottom: 50px;
  }

}