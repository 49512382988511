.art-bg{
  background-image: url('../../../../assets/img/intro/art_bg.png');  // 请根据实际图片路径修改
  background-size: cover;

  width: 100%;


}

.art-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.art-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;
}

.art-title {
  color: rgba(0, 0, 0, 1);
  font-family: Arial;
  font-weight: 700;
  font-size: 36px;

}

.art-learn-more {
  color: rgba(51, 51, 51, 1);
  margin-bottom: 15px;
  font-family: Arial;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  text-decoration-line: underline;
  &:hover {
    text-decoration: underline;
  }
}



.introart-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;

  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.introart-card {
  background: #ffffff;
  border-radius: 20px;
  border: 3px solid #000000;
  box-sizing: border-box;
  overflow: hidden;
  transition: transform 1.8s ease;
  opacity: 0;
  transform: translateY(30px);
  padding: 10px;

  &.introart-card-visible {
    animation: introartFadeIn 1.6s ease forwards;
  }

  &:hover {
    transform: translateY(-5px);
  }
}

@keyframes introartFadeIn {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.introart-image-wrapper {
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.introart-image {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
  transition: transform 0.8s ease;

  &:hover {
    transform: scale(1.05);
  }
}

.introart-content {
  padding: 10px;
}

.introart-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 12px;
  color: #333;
}

.introart-description {
  font-size: 14px;
  line-height: 1.6;
  color: #666;
  margin: 0;
}

/* Mobile Optimizations */
@media screen and (max-width: 768px) {
  .introart-container {
    padding: 16px;
  }

  .introart-card {
    margin-bottom: 16px;
  }

  .introart-image-wrapper {
    height: 180px;
  }

  .introart-content {
    padding: 16px;
  }

  .introart-title {
    font-size: 20px;
    margin-bottom: 8px;
  }

  .introart-description {
    font-size: 13px;
  }
}