.homeabout-wrapper{
  // 方式一：使用 background-image
  background-image: url('../../../../assets/img/h/about_bg.png');  // 请根据实际图片路径修改
  background-size: cover;

  width: 100%;
  padding: 20px 0;
  height: 170px;
}

.homeabout-container {
  z-index: 2; // 确保内容在背景遮罩层之上
  max-width: 1200px;
  padding: 20px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.homeabout-title {
  color: #ffffff;
  font-family: Arial;
  font-weight: 700;
  font-size: 36px;
  margin-bottom: 20px;
}

.homeabout-btncontainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}