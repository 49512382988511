.root{
  padding-top: 120px;
  min-height: calc(100vh - 190px);
  width: 70%;
  margin-left: 15%;
}

.container-finance{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  padding-top: 0px;
}

.finance-title{
  font-family: Microsoft YaHei;
  font-weight: bold;
  font-size: 40px;
  color: #000000;
}

.flex-container-finance {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.card-finance {
  width: calc(70% - 10px); /* 容器宽度的一半减去间距的一半 */
  height: 460px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}

.card2-finance {
  width: calc(30% - 10px); /* 容器宽度的一半减去间距的一半 calc(30% - 10px) */
  height: 460px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 20px;
}

.finance-amount{
  font-size: 35px;
  font-weight: bold;
  background: linear-gradient(0deg, rgba(224,240,38,0.96) 0%, rgba(13,184,54,0.96) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.finance-card-log{
  width: 100%; /* 容器宽度的一半减去间距的一半 */
  margin-top: 40px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 20px;
}


/* 为移动端添加媒体查询 */
@media (max-width: 768px) {
  .root {
    width: 100%;
    margin-left: 0; /* 确保不再有左侧的空隙 */
  }

  .flex-container-finance {
    flex-direction: column;
  }

  .card-finance {
    width: 100%;
    margin-bottom: 20px;
  }

  .card2-finance {
    width: 100%;
    margin-bottom: 20px;
  }
}