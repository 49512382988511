@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  width: 100%;
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
     "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
     sans-serif;*/
  font-family: "Kanit", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 80%;
}
* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.comm_pad_btn_normal {
  margin-left: 0.2rem;
  padding: 0.5rem 1.5rem;
  text-align: center;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  background: #5284ff;
  color: white;
}

.common_btn {
  margin-left: 0.2rem;
  padding: 0 10px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  background: #5284ff;
  color: #fff;
}

.disable_btn {
  background: rgb(128, 128, 128);
}

.safe_area {
  width: 100%;
  padding-left: 4rem;
  padding-right: 4rem;
  padding-top: 1.5rem;
}

.safe_area_head {
  width: 100%;
  padding-left: 5rem;
  padding-right: 5rem;
  padding-top: 0.5rem;
}

.head-logo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.top-icon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.main-title {
  display: flex;
  font-size: 1.6rem;
}

.title-beta {
  color: gray;
  font-size: 1.2rem;
}

.mobile-menu {
  margin-left: 1rem;
  display: none;
}

.nav-menu-item {
  display: flex;
  justify-content: center;
  align-items: center;
}
img {
  display: block;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
a {
  text-decoration: none;
}

.modal_open .header {
  width: calc(100% - 17px);
}

@media screen and (max-width: 768px) {
  .safe_area_head {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .head-logo .main-title {
    display: none;
  }

  .mobile-menu {
    display: block;
  }
  .modal_open .header {
    width: 100%;
  }
}
