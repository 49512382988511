.root{
  padding-top: 120px;
  min-height: calc(100vh - 190px);
  width: 70%;
  margin-left: 15%;
}

.container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.commingsoon{
  margin-top: 200px;
  display: block;
  width: 200px; /* 将宽度设置为原始宽度的 50% */
  height: auto; /* 保持高度与宽度成比例 */
}


/* 为移动端添加媒体查询 */
@media (max-width: 768px) {
  .root {
    width: 100%;
    margin-left: 0; /* 确保不再有左侧的空隙 */
  }
}