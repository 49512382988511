.root{
  padding-top: 120px;
  min-height: calc(100vh - 190px);
  width: 70%;
  margin-left: 15%;
}

.container-dao{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex-container-dao {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 30px;
  padding-top: 0px;
}

.card-dao {
  width: calc(20% - 10px); /* 容器宽度的一半减去间距的一半 */
  height: 460px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-size: 24px;
  color: #222222;
  font-weight: bold;
}

.card2-dao {
  width: calc(80% - 10px); /* 容器宽度的一半减去间距的一半 */
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 20px;
}

.card3-dao{
  margin-top: 30px;
  border: 1px solid #eaeaea; /* 边框颜色和宽度 */
  border-radius: 10px; /* 圆角半径 */
  padding: 16px; /* 内边距 */
  width: 100%; /* 宽度 */
  background-color: #fafafa; /* 背景色 */
  display: flex;
  flex-direction: column;
}

.progress-bar-container-dao {
  margin-top: 20px;
  width: 100%; /* 固定宽度 */
  height: 50px; /* 固定高度 */
  background-color: #e0e0e0;
  border-radius: 20px;
  overflow: hidden;
  border: 1px solid #eaeaea; /* 添加边框 */
  position: relative;
}

.progress-bar-dao {
  height: 100%;
  background: linear-gradient(to right, rgba(224,240,38,0.96) 0%, rgba(13,184,54,0.96) 100%); /* 渐变颜色 */
  transition: width 0.3s ease;
}

.progress-left-text-dao {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px; /* 左边距 */
  color: #000; /* 文字颜色 */
  font-size: 20px;
  font-weight: bold;
}

.progress-right-text-dao {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px; /* 右边距 */
  color: #000; /* 文字颜色 */
  font-size: 18px;
  font-weight: bold;

}





/* 为移动端添加媒体查询 */
@media (max-width: 768px) {
  .root {
    width: 100%;
    margin-left: 0; /* 确保不再有左侧的空隙 */
  }

  .card-dao{
    display: none;
  }

  .card2-dao{
    width: 100%;
  }
}