.intromore {
  &-container {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-card {
    min-height: 200px;
    border-radius: 20px;
    padding: 32px;
    background-image: url('../../../../assets/img/intro/more_bg.png');
    background-size: cover;
    background-position: center;
    width: 1200px;


    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  &-title {
    font-size: 36px;
    font-weight: bold;
    color: white;
    margin-bottom: 24px;
  }

  &-buttons {
    display: flex;
    gap: 16px;
  }

  &-btn {
    padding: 12px 24px;
    border-radius: 24px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;

    &-primary {
      background: white;
      color: #4CAF50;
      border: none;
    }

    &-secondary {
      background: rgba(255, 255, 255, 0.2);
      color: white;
      border: none;
    }
  }

  &-divider {
    width: 100%;
    height: 1px;
    background-color: #E0E0E0;
    margin-top: 20px;
  }
}

@media (max-width: 768px) {
  .intromore {
    &-card {
      padding: 24px;
    }

    &-title {
      font-size: 28px;
    }

    &-buttons {
      flex-direction: column;
    }

    &-btn {
      width: 100%;
    }
  }
}