.homeassets-wrapper{
  // 方式一：使用 background-image
  background-image: url('../../../../assets/img/h/home_bg.png');  // 请根据实际图片路径修改
  background-size: cover;

  width: 100%;
  padding: 20px 0;
}


.homeassets-container {
  position: relative;
  z-index: 2; // 确保内容在背景遮罩层之上
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.homeassets-title {
  color: rgba(0, 0, 0, 1);
  font-family: Arial;
  font-weight: 700;
  font-size: 36px;
  margin-bottom: 20px;
}

.homeassets-content {
  display: flex;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.homeassets-chart-container {
  flex: 2;
  background: #fff;
  border-radius: 20px;
  border: 3px solid rgba(0, 0, 0, 1);
  box-sizing: border-box;
  background: rgba(255, 255, 255, 1);
}

.homeassets-chart-image {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}


.homeassets-property-container {
  flex: 1;
  background: #fff;
  border-radius: 20px;
  border: 3px solid rgba(0, 0, 0, 1);
  box-sizing: border-box;
  background: rgba(255, 255, 255, 1);
}

.homeassets-property-image {
  width: 100%;
  height: 400px;
  border-radius: 18px;
}

.homeassets-price-container {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  padding-left: 30px;
  padding-right: 30px;
  @media (max-width: 480px) {
    gap: 10px;
    padding-bottom: 10px;
  }
}

.homeassets-price-item {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.homeassets-price-label {
  color: rgba(153, 153, 153, 1);
  font-family: Arial;
  font-weight: 400;
  font-size: 16px;
}

.homeassets-price-value {
  font-family: Arial;
  font-weight: 700;
  font-size: 22px;

  &.homeassets-price-up {
    color: rgba(229, 28, 28, 1);
  }

  &.homeassets-price-down {
    color: rgb(0, 149, 36);
  }
}
