.send_twitter_btn{
  margin-left: .2rem;
  padding: .5rem 1.5rem;
  text-align: center;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  background: #5284ff;
  color: white;
}


.table_btns{
  width: 120px;
}
.marginLeft{
  margin-left: 10px;
}
.marginRight{
  margin-right: 10px;
}

.login_button {
  width: 100%;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border-radius: 5px;
  border: 1px solid #333;
  background: #5284ff;
  color: white;
  font-family: Overpass;
  border: none;
  overflow: hidden;
  &:hover {
    cursor: pointer;
  }
}

.login-info{
  width: 100%;
  .unlogin{
    display: flex;
    justify-content: center;
    align-content: center;
  }

  .project-divs{
    .user-area{
      display: flex;
      justify-content: space-between;
      .user-info{
        display: flex;
        flex-direction: column;
        .row-left{
          display: flex;
          .user-avater{
            width: 5rem;
            height: 5rem;
          }
          .user-infos{
            display: flex;
            .left-info{
              margin-left: .6rem;
              font-size: .9rem;
              font-weight: 400;
              .title{

              }
            }
            .right-info{
              margin-left: 1rem;
            }
          }
        }
        .row-address{
        }
        .eth-area {
          display: flex;
          margin-top: .25rem;
          > img {
            width: 1.2rem;
            margin-right: 1rem;
          }
          .proj-total{
            margin-left: 2rem;
          }

          .eth-name{
            margin-left: .3rem;
            text-decoration-line: underline;
            &:hover {
              cursor: pointer;
              text-decoration-line: underline;
              color:blue;
            }
          }
        }
      }

      .right-info{
        align-items: end;
      }
    }
  }
}

.air_invite {
  margin-top: 3rem;
  width: 100%;
  .area_row_input {
    display: flex;
    margin-top: .2rem;
    height: 40px;
    line-height: 40px;
    align-items: center;
    .modal_input {
      margin-left: .2rem;
      border: .01rem solid gray;
      height: 40px;
      line-height: 40px;
      border-radius: .1rem;
      background-color: white;
      color: black;
      padding-left: .2rem;
    }

    .modal_progress {
      margin-left: .1rem;
    }

    .modal_err {
    }
  }
  .area_check_view{
    .title{
      margin-top: .3rem;
      display: flex;
      height: 40px;
      line-height: 40px;
    }
    .row{
      display: flex;
      margin-top: .2rem;
      margin-left: 1rem;
      line-height: 24px;
      .minWidth{
        width: 280px;
      }
      .modal_progress{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: .2rem;
      }
    }
  }
  .ant-table-wrapper{

  }
}

.rule_modal_box{
  .ant-modal-content{
    .ant-modal-body{
      .title{
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: .5rem;
        justify-content: center;
        font-size: 20px;
      }
      .send_tw_dialog{
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 40px;
        font-size: 20px;
        .search-row{
          margin-top: 15px;
          font-size: 1rem;
        }
        .search-form{
          margin-top: 10px;;
          flex: 1;
          display: flex;
          width: 100%;
          height: 50px;
          border-radius: 5px;
          border: 1px solid #333;
          padding: 0px 20px;
          font-size: 1rem;
          input {
            padding-left: 10px;
            width: 100%;
            border: none;
            border-radius: 0;
            font-family: Overpass;
            font-weight: 100;
            font-size: 1rem;
            &:focus {
              outline: 0;
            }
            &::-webkit-input-placeholder {
              color: gray;
            }
          }
          .end{
            text-align: center;
            height: 50px;
            line-height: 50px;

          }
        }
        .search-form-area{
          height: 100px;
        }
        .search-text{
          font-size: 14px;
          margin-top: .5rem;
        }
        .earch_button {
          margin-left: 20px;
          border-radius: 5px;
          border: 1px solid #333;
          background: #5284ff;
          color: white;
          font-family: Overpass;
        //padding: 20px 0;
          width: 162px;
          border: none;
          overflow: hidden;
          &:hover {
            cursor: pointer;
          }
        }
      }
      .box{
        .vote_type{
          margin-bottom: .5rem;
        }
        .vote_year{
          margin-bottom: .5rem;
        }
        .vote_price{
          margin-bottom: .5rem;
        }
      }
      .buttom{
      }
    }
  }
}

.table-twt {
  border-collapse: collapse;
  font-size: 12px;
  color: #404040;
  & td, & th {
    border: 1px solid black;
  }
}


@media screen and (max-width: 768px) {
  .project-divs{
    /*color:white;*/
  }

  .login_button{
    width: 100%;
    padding: .5rem;
  }

  .air_invite {
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 100%;
    padding: 16px;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.85);
    .title {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
    }
  }

  .fast-border {
    flex-direction: column;
    margin-top: 1.25rem;
    .fast-item {
      width: 100%;
      margin: 0 auto;
      padding: 1.25rem;
      margin-bottom: 1.5625rem;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}
