.root{
  padding-top: 120px;
  min-height: calc(100vh - 190px);
  width: 70%;
  margin-left: 15%;
}

.container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.commingsoon{
  margin-top: 200px;
  display: block;
  width: 200px; /* 将宽度设置为原始宽度的 50% */
  height: auto; /* 保持高度与宽度成比例 */
}


.nftpage-container {
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 150px;

  .nftpage-title {
    text-align: center;
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 40px;
    color: #1a1a1a;
  }

  .nftpage-cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;

    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

  .nftpage-card {
    background: #ffffff;
    border-radius: 16px;
    padding: 24px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;

    &:hover {
      transform: translateY(-5px);
    }

    .nftpage-card-image {
      width: 100%;
      height: 500px;
      border-radius: 12px;
      object-fit: cover;
      margin-bottom: 16px;

      @media screen and (max-width: 768px) {
        height: 350px;
      }
    }

    .nftpage-card-title {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 12px;
      color: #333;
    }

    .nftpage-card-description {
      font-size: 16px;
      color: #666;
      margin-bottom: 16px;
      line-height: 1.5;
    }

    .nftpage-card-price {
      font-size: 20px;
      font-weight: 600;
      color: #1a1a1a;
      margin-bottom: 12px;

      .nftpage-eth-icon {
        width: 20px;
        height: 20px;
        margin-right: 8px;
        vertical-align: middle;
      }
    }

    .nftpage-card-presale {
      font-size: 14px;
      color: #888;
      margin-bottom: 20px;
    }

    .nftpage-card-button {
      width: 100%;
      padding: 12px 24px;
      background: #009524;
      color: white;
      border: none;
      border-radius: 8px;
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
      transition: background 0.3s ease;

      &:hover {
        opacity: 0.9
      }

      &:disabled {
        background: #ccc;
        cursor: not-allowed;
      }
    }
  }
}


/* 为移动端添加媒体查询 */
@media (max-width: 768px) {
  .root {
    width: 100%;
    margin-left: 0; /* 确保不再有左侧的空隙 */
  }
}