.token-bg{
  background-image: url('../../../../assets/img/intro/token_bg.png');  // 请根据实际图片路径修改
  background-size: cover;

  width: 100%;
}

.introtoken-container {
  display: flex;
  gap: 24px;
  padding: 20px;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 100px;
}

.introtoken-card {
  flex: 1;
  min-width: 300px;
  max-width: 500px;
  background: white;
  padding: 24px;
  border-radius: 20px;
  border: 2px solid #000000;
  box-sizing: border-box;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  }
}

.introtoken-content {
  display: flex;
  gap: 24px;
  margin-bottom: 24px;
}

.introtoken-left {
  flex: 1;
}

.introtoken-right {
  width: 120px;
  flex-shrink: 0;
}

.introtoken-image {
  width: 120px;
  height: 120px;
  border-radius: 12px;
}

.introtoken-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
}

.introtoken-description {
  color: #666;
  line-height: 1.6;
  margin-bottom: 24px;
}

.introtoken-buttons {
  display: flex;
  gap: 16px;
}

.introtoken-buy {
  background: #4CAF50;
  color: white;
  padding: 12px 24px;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background: #43A047;
  }
}

.introtoken-learn {
  color: #009524;
  padding: 12px 0;
  font-size: 16px;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: #43A047;
  }
}

.introtoken-metrics {
  display: flex;
  gap: 12px;
}

.introtoken-metric-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 16px;
  background: #f5f5f5;
  border-radius: 8px;
  text-align: center;
}

.introtoken-value {
  font-size: 18px;
  font-weight: bold;
  color: #4CAF50;
}

.introtoken-label {
  font-size: 14px;
  color: #666;
}

@media (max-width: 768px) {
  .introtoken-card {
    min-width: 100%;
  }

  .introtoken-content {
    flex-direction: column;
    align-items: center;
  }

  .introtoken-right {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .introtoken-buttons {
    flex-direction: column;
    align-items: center;
  }

  .introtoken-learn {
    padding: 0;
  }

  .introtoken-metrics {
    flex-direction: column;
  }
}