.nft_list {
  width: 100%;
  margin-top: 20px;
  border: 1px solid #004473;
  border-radius: 10px;
  padding: 11px 11px 20px;
  .nft_list_top {
    .nft_list_title {
      display: flex;
      align-items: center;
      .nft_list_title_logo {
        width: 39px;
        height: 39px;
      }
      .nft_list_title_f {
        font-size: 16px;
        margin-left: 10px;
      }
      .nft_list_title_font {
        height: 17px;
        margin-left: 10px;
      }

      .more {
        width: 53px;
        height: 19px;
        background: url("../assets/img/home/morebr.png");
        background-size: 100% 100%;
        margin-left: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 10px;
        > img {
          width: 21px;
        }
      }
      .nft_title_btn {
        margin-left: auto;
        width: 71px;
        height: 26px;
        text-align: center;
        line-height: 26px;
        font-size: 10px;
        background: url("../assets/img/home/btn_border.png");
        background-size: 100% 100%;
      }
    }
    .show_pc {
      display: none;
    }
    .tmir {
      text-align: right;
      font-size: 24px;
    }
    .tmir_usdt {
      text-align: right;
      font-size: 14px;
    }
  }

  .may_tabs {
    .ant-tabs-nav {
      margin-bottom: 0;
      &::before {
        display: none;
      }
      .ant-tabs-tab-active {
        padding-bottom: 2px;
        .ant-tabs-tab-btn {
          color: #fff;
          font-size: 16px;
        }
      }

      .ant-tabs-ink-bar {
        transform: scaleX(0.5) translateX(-150%) !important;
        background: #5ae4f4;
      }
    }
  }
  .nft_mall_tabs {
    .ant-tabs-nav {
      margin-bottom: 0;
      &::before {
        display: none;
      }
      .ant-tabs-tab {
        padding: 0;
        color: #fff;
        font-size: 16px;
      }
      .ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          font-size: 16px;
          color: #fff;
        }
      }

      .ant-tabs-ink-bar {
        background: #5ae4f4;
      }
    }
  }
  .nft_list_con {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 30px;
    gap: 10px;
  }

  .pageDiv {
    margin-top: 1.2rem;
    .ant-pagination {
      .ant-pagination-prev {
        background: #0d87d7;
      }
      .ant-pagination-item {
        background: #0d87d7;
      }
      .ant-pagination-next {
        background: #0d87d7;
      }
      .ant-pagination-item-active {
        background: #fff;
      }
    }
  }
}

.carouse_modal_root {
  .ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.8);
  }
}
.carouse_modal {
  width: 80% !important;
  .ant-modal-content {
    padding: 0;
    background: transparent;
    border-radius: 10px;
    .ant-modal-body {
      position: relative;
      .carousel_closa {
        position: relative;
        > img {
          width: 30px;
          height: 30px;
          position: absolute;
          top: -40px;
          right: 0;
        }
      }

      .carouse_modal_con {
        width: 100%;
        height: 500px;
        position: relative;
        .carouse_modal_img {
          width: 100%;
          height: auto;
          border-radius: 10px;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
        }
        .carouse_modal_mask {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: linear-gradient(to bottom, transparent, rgb(0, 0, 0));
          z-index: 2;
        }
        .carouse_modal_info {
          width: 100%;
          height: 100%;
          position: relative;
          z-index: 3;
          color: #fff;
          display: flex;
          flex-direction: column;
          justify-content: end;
          .name {
            margin-left: 10px;
            .name_con {
              display: inline-block;
              padding: 5px 10px;
              background: #308adb;
              border-radius: 10px;
              font-size: 14px;
              font-weight: 700;
            }
          }
          .price {
            margin-left: 10px;
            margin-top: 10px;
            .price_con {
              display: inline-block;
              .price_c {
                display: flex;
                align-items: center;
                padding: 5px 10px;
                background: #308adb;
                border-radius: 10px;
                font-size: 14px;
                > img {
                  width: 18px;
                  height: 18px;
                  margin-right: 10px;
                }
                .price_currency {
                  padding: 0 5px;
                }
              }
            }
          }
          .explain {
            margin-left: 10px;
            margin-top: 30px;
            margin-bottom: 10px;
            .explain_t {
              font-size: 14px;
            }
            .explain_c {
              height: 48px;
              font-size: 12px;
              line-height: 16px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
      .carouse_modal_carousel {
        .slick-prev {
          opacity: 1;
          &:after {
            display: none;
          }
          width: 16px;
          height: 26px;
          background: url("../assets/img/home/arrow.png");
          background-size: 100% 100%;
          position: absolute;
          top: 50%;
          left: -20px;
          transform: translateY(-50%);
          z-index: 4;
        }
        .slick-next {
          opacity: 1;
          &:after {
            display: none;
          }
          width: 16px;
          height: 26px;
          background: url("../assets/img/home/arrow.png");
          background-size: 100% 100%;
          transform: translateY(-50%) rotate(180deg);
          position: absolute;
          top: 50%;
          right: -20px;
          z-index: 4;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .nft_list {
    .nft_list_top {
      margin-bottom: 20px;
      .nft_list_title {
        .nft_list_title_logo {
          width: 70px;
          height: 70px;
        }
        .nft_list_title_f {
          display: none;
        }
        .nft_list_title_font {
          height: 20px;
        }
        .more {
          width: 100px;
          height: 40px;
          font-size: 14px;
          > img {
            width: 40px;
          }
        }
      }
      .show_pc {
        display: block;
        .show_pc_t {
          font-size: 30px;
          color: #00a9f1;
          font-weight: bold;
          text-align: center;
          margin: 10px 0;
        }
        .show_pc_address {
          width: 150px;
          height: 40px;
          background: url("../assets/img/header/check_in.png");
          background-size: 100% 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          .ant-typography {
            margin-bottom: 0;
            color: #fff;
            .ant-typography-copy {
              color: #fff;
            }
          }
        }
      }
      .tmir {
        margin-top: 30px;
      }
      .tmir_usdt {
        transform: translateX(100px);
      }
    }
    .nft_list_top_profile {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .nft_list_con {
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
    }
  }
  .carouse_modal {
    width: 520px !important;

    .ant-modal-content {
      .ant-modal-body {
        .carouse_modal_con {
          height: 700px;

          .carouse_modal_info {
            .name {
              .name_con {
                padding: 10px;
              }
            }
            .price {
              .price_con {
                .price_c {
                  padding: 10px;
                  > img {
                    width: 20px;
                    height: 20px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
