.parent-bg{
  background-image: url('../../../../assets/img/intro/parent_bg.png');  // 请根据实际图片路径修改
  background-size: cover;
  width: 100%;
  padding-bottom: 50px;
}


.introparent-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.introparent-title {
  text-align: center;
  color: rgba(0, 0, 0, 1);
  font-family: Arial;
  font-weight: 700;
  font-size: 36px;
  margin-bottom: 40px;
}

.introparent-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.introparent-card {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background: white;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
}

.introparent-logo {
  width: 100%;
  height: auto;
  max-width: 150px;
  object-fit: contain;
}