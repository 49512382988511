.introtop-wrapper{
  // 方式一：使用 background-image
  background-image: url('../../../../assets/img/intro/top_bg.png');  // 请根据实际图片路径修改
  background-size: cover;

  width: 100%;
  height: 650px;

  @media (max-width: 768px) {
    height: 500px;
    background-image: url('../../../../assets/img/intro/top_bg.png');  // 请根据实际图片路径修改
    background-size: cover; /* 确保图片完整展示 */
    background-repeat: no-repeat; /* 防止图片重复 */
  }
}

.introtop-container {
  z-index: 2; // 确保内容在背景遮罩层之上
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  padding-top: 60px;
}

.introtop-area1{
  flex: 1;
  display: flex;
  flex-direction: column;
}

.introtop-area2{
  flex:1
}

.introtop-title{
  color: #009524;
  font-family: Arial;
  font-weight: 700;
  font-size: 56px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 47px
  }
}

.introtop-btns{
  display: flex;
  flex-direction: row;
}

.introtop-map{
  width: 600px;
  height: 600px;
}





.introtop-card {
  position: absolute;
  top: 300px;
  left: calc(50vw + 350px);
  width: 288px; // 72 * 4px
  height: 300px;
  background: white;
  border-radius: 20px;
  border: 3px solid #000000;
  overflow: hidden;
  transition: transform 1.7s ease;
  animation: fadeIn 2.2s ease-out forwards;
  padding: 5px;

  &:hover {
    transform: scale(1.05);
  }

  .card-image {
    position: relative;
    width: 100%;
    height: 192px; // 48 * 4px

    img {
      border-radius: 12px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .card-content {
    padding: 10px;

    .card-title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 8px;
      color: #333;
    }

    .card-subtitle {
      color: #666;
      margin-bottom: 5px;
      font-size: 14px;
    }

    .card-link {
      display: inline-flex;
      align-items: center;
      color: #999;
      cursor: pointer;
      transition: color 0.3s ease;

      &:hover {
        color: #666;
      }

      svg {
        margin-left: 4px;
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}