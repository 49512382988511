.intronft-bg{
  background: #f2fff9;
  width: 100%;
}

.intronft-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.intronft-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;
}

.intronft-title {
  color: rgba(0, 0, 0, 1);
  font-family: Arial;
  font-weight: 700;
  font-size: 36px;
}


.intronft-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;

  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.intronft-card {
  background: #ffffff;
  border-radius: 20px;
  border: 3px solid #000000;
  box-sizing: border-box;
  overflow: hidden;
  transition: transform 0.8s ease;
  opacity: 0;
  transform: translateY(30px);
  padding: 10px;

  &.intronft-card-visible {
    animation: intronftFadeIn 1.6s ease forwards;
  }

  &:hover {
    transform: translateY(-5px);
  }
}


.intronft-card2 {
  background: #ffffff;
  border-radius: 20px;
  border: 3px solid #000000;
  box-sizing: border-box;
  overflow: hidden;
  transition: transform 0.8s ease;
  opacity: 0;
  transform: translateY(30px);

  &.intronft-card-visible2 {
    animation: intronftFadeIn 1.6s ease forwards;
  }

  &:hover {
    transform: translateY(-5px);
  }
}

@keyframes intronftFadeIn {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.intronft-image-wrapper {
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.intronft-image {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
  transition: transform 0.8s ease;

  &:hover {
    transform: scale(1.05);
  }
}

.intronft-image2 {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
  transition: transform 0.8s ease;
  background-image: url('../../../../assets/img/intro/nft_pic_soon.png');  // 请根据实际图片路径修改
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    transform: scale(1.05);
  }
}

.intronft-image2-text{
  color: #005715;
  font-family: Arial;
  font-weight: 700;
  font-size: 30px;
  line-height: 30px;
}

.intronft-content {
  padding: 10px;
}

.intronft-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 12px;
  color: #333;
}

.intronft-description {
  font-size: 14px;
  line-height: 1.6;
  color: #666;
  margin: 0;
}

/* Mobile Optimizations */
@media screen and (max-width: 768px) {
  .intronft-container {
    padding: 16px;
  }

  .intronft-card {
    margin-bottom: 16px;
  }

  .intronft-image-wrapper {
    height: 180px;
  }

  .intronft-content {
    padding: 16px;
  }

  .intronft-title {
    font-size: 20px;
    margin-bottom: 8px;
  }

  .intronft-description {
    font-size: 13px;
  }
}