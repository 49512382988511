.homenav-wrapper{
  // 方式一：使用 background-image
  background-image: url('../../../../assets/img/h/home_top_bg.png');  // 请根据实际图片路径修改
  background-size: cover;

  width: 100%;
  height: 100%;

  @media (max-width: 768px) {
    height: 600px;
    background-image: url('../../../../assets/img/h/home_top_bg_mobile.png');  // 请根据实际图片路径修改
    background-size: cover; /* 确保图片完整展示 */
    background-repeat: no-repeat; /* 防止图片重复 */
  }
}

.homenav-container {
  position: relative;
  z-index: 2; // 确保内容在背景遮罩层之上
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.homenav-title {
  color: #333333;
  font-family: Arial;
  font-weight: 700;
  font-size: 56px;
  margin-bottom: 20px;
}

.homenav-title2 {
  color: #009524;
  font-family: Arial;
  font-weight: 700;
  font-size: 56px;
  margin-bottom: 20px;
}

.floating-card {
  margin-top: 30px;
}

.data-section {
  display: flex;
}

.data-item {
  text-align: center; /* 使文字在每个数据块内水平居中 */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.data-number {
  color: #333333;
  font-weight: 700;
  font-size: 40px;
  @media (max-width: 768px) {
    font-size: 22px;
  }
}

.data-title {
  opacity: 0.5;
  color: #333333;
  font-family: Arial;
  font-weight: 400;
  font-size: 18px;
  @media (max-width: 768px) {
    font-size: 15px;
  }
}

.divider {
  width: 1px;
  margin: 30px;
  height: 40px; /* 确保分割线不会超出父容器 */
  background: #dddddd;
  @media (max-width: 768px) {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 15px;
  }
}